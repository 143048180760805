import { useMemo } from "react";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Separator } from "@/components/ui/separator";
import { Address } from "@/components/Address";

export function AddressMapView({ obj }: { obj: Record<string, string> }) {
  const entries = useMemo(() => Object.entries(obj), [obj]);
  return (
    <ScrollArea className="flex h-20 font-medium flex-1">
      {entries.map((entry, i) => (
        <>
          <div key={i} className="flex justify-between flex-row py-2">
            <Address address={entry[0]} copyable />
            {entry[1]}
          </div>
          {i < entries.length - 1 && <Separator className="my-2" />}
        </>
      ))}
    </ScrollArea>
  );
}
