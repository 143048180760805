import { Decimal } from "decimal.js";
import { Link, useParams } from "react-router-dom";
import { ArrowRight, CoinsIcon } from "lucide-react";
import { Tree } from "primereact/tree";
import { useSmartContract, useAccount } from "@/hooks";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { NetworkLink } from "@/components/NetworkLink";
import { TransactionList } from "@/components/TransactionList";
import { InfoCardCell } from "@/components/InfoCardCell";
import { PageContentHeader } from "@/components/PageContentHeader";
import { EventList } from "@/components/EventList";
import { AccountIncomingTransferList } from "@/components/AccountIncomingTransferList";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { SourceCodeCard } from "@/components/SourceCode";
import { CopyableText } from "@/components/CopyableText";
import { truncateInMiddle, satsToBTC } from "@/lib";
import { BTCPriceConversion } from "@/components/BTCPriceConversion";
import { TokenBalancesList } from "@/components/TokenBalancesList";
import { TokenHoldersList } from "@/components/TokenHoldersList";
import { ContractInteraction } from "@/components/ContractInteraction";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";

const addressTypeNameMap: Record<string, string> = {
  unified: "Unified",
  p2tr: "Taproot",
  p2pkh: "Legacy",
  p2shp2wpkh: "Nested SegWit",
  p2wpkh: "Native SegWit",
  pubkey: "Public Key",
  uncompressedPubkey: "Uncompressed Public Key",
  hybridPubKey: "Hybrid Public Key",
  unifiedHybrid: "Unified Hybrid",
};

function AddressInfoCard({ id, balance, numberOfTokens, addressType }: any) {
  return (
    <Card className="overflow-hidden w-full">
      <CardHeader>
        <CardTitle>Summary</CardTitle>
      </CardHeader>
      <CardContent className="flex flex-col gap-2 grow">
        <InfoCardCell
          title={
            addressType
              ? `Address (${addressTypeNameMap[addressType]})`
              : "Address"
          }
          value={
            <CopyableText
              text={id}
              displayText={id && truncateInMiddle(id, 16)}
            />
          }
        />
        <InfoCardCell
          title="BTC Balance"
          value={
            <>
              {`${balance} BTC `}
              <BTCPriceConversion
                amount={balance}
                className="text-xs font-medium text-muted-foreground"
              />
            </>
          }
        />
        <InfoCardCell
          title="Number of Tokens"
          value={numberOfTokens?.toLocaleString("en-US")}
        />
      </CardContent>
    </Card>
  );
}

function ContractInfoCard({ id, stxBalance, numberOfTokens, contract }: any) {
  return (
    <Card className="overflow-hidden grow">
      <CardHeader>
        <CardTitle>Summary</CardTitle>
      </CardHeader>
      <CardContent className="flex flex-col gap-2 grow">
        <InfoCardCell
          title="Contract Address"
          value={
            <CopyableText
              text={contract?.address}
              displayText={
                contract?.address && truncateInMiddle(contract?.address, 16)
              }
            />
          }
        />
        {contract?.pubKey}
        <InfoCardCell
          title="BTC Balance"
          value={
            <>
              {`${stxBalance} BTC `}
              <BTCPriceConversion
                amount={stxBalance}
                className="text-xs font-medium text-muted-foreground"
              />
            </>
          }
        />
        <InfoCardCell
          title="Numer of Tokens"
          value={numberOfTokens?.toLocaleString("en-US")}
        />
        <InfoCardCell
          title="Deployed By"
          value={
            <CopyableText
              text={contract?.deployerAddress}
              displayText={
                contract?.deployerAddress &&
                truncateInMiddle(contract?.deployerAddress, 16)
              }
              href={`/accounts/${contract?.deployerAddress}`}
            />
          }
        />
        <InfoCardCell
          title="Deploy Transaction"
          value={
            <CopyableText
              text={contract?.deployTransactionId}
              displayText={
                contract?.deployTransactionId &&
                truncateInMiddle(contract?.deployTransactionId, 16)
              }
              href={`/transactions/${contract?.deployTransactionId}`}
            />
          }
        />
      </CardContent>
      <CardFooter className="p-0">
        <Button className="flex-1 rounded-none" asChild>
          <NetworkLink to={`/transactions/${contract?.deployTransactionId}`}>
            View Deploy Transaction <ArrowRight className="ml-1 h-5 w-5" />
          </NetworkLink>
        </Button>
      </CardFooter>
    </Card>
  );
}

function TokenInfoCard({ metadata }: any) {
  const decimalDiv = 10n ** BigInt(metadata.decimals ?? 0);
  const totalSupply = new Decimal(metadata.totalSupply).dividedBy(
    decimalDiv.toString()
  );
  const maximumSupply = new Decimal(metadata.maximumSupply).dividedBy(
    decimalDiv.toString()
  );
  return (
    <Card className="flex flex-col overflow-hidden lg:w-1/3">
      <CardHeader>
        <CardTitle className="flex justify-between items-center">
          OP20 Token
          <CoinsIcon className="text-[#FF7900] h-5 w-5" />
        </CardTitle>
      </CardHeader>
      <CardContent className="flex flex-col gap-2 grow">
        <InfoCardCell title="Name" value={metadata.name || "Unknown"} />
        <InfoCardCell title="Symbol" value={metadata.symbol || "Unknown"} />
        <InfoCardCell
          title="Max Supply"
          value={maximumSupply.toNumber().toLocaleString("en-US")}
        />
        <InfoCardCell
          title="Total Supply"
          value={totalSupply.toNumber().toLocaleString("en-US")}
        />
        {metadata?.owner && (
          <InfoCardCell
            title="Owner"
            value={
              <CopyableText
                text={metadata?.owner}
                displayText={truncateInMiddle(metadata?.owner, 16)}
              />
            }
          />
        )}
      </CardContent>
      {metadata.owner && (
        <CardFooter className="p-0">
          <Button
            className="flex-1 rounded-none uppercase bg-[#FF7900]"
            asChild
          >
            <NetworkLink to={`/accounts/${metadata.owner}`}>
              View Owner <ArrowRight className="ml-1 h-5 w-5" />
            </NetworkLink>
          </Button>
        </CardFooter>
      )}
    </Card>
  );
}

export function AccountPage() {
  const { id } = useParams();
  const { account } = useAccount({ address: id ?? "" });
  const { contract } = useSmartContract({ id: id ?? "" });
  const isContract = contract?.address;

  const balance = satsToBTC(account?.balance ?? 0);

  return (
    <div className="flex container p-4 space-y-4 flex-col">
      <PageContentHeader
        title={`${id && truncateInMiddle(id, 20)}`}
        accessory={isContract && <ContractInteraction contract={contract} />}
      />
      {isContract && (
        <div className="flex flex-col lg:flex-row gap-4">
          <ContractInfoCard
            id={id}
            stxBalance={balance}
            numberOfTokens={account?.tokenCount ?? 0}
            contract={contract}
          />
          {contract?.isOP20 && (
            <TokenInfoCard metadata={contract?.op20Metadata ?? {}} />
          )}
        </div>
      )}
      {!isContract && (
        <AddressInfoCard
          id={id}
          balance={balance}
          numberOfTokens={account?.tokenCount ?? 0}
          addressType={account?.addressType}
        />
      )}
      {account?.associatedAddresses?.length > 0 && (
        <Card className="flex flex-col overflow-hidden grow">
          <CardContent>
            <Accordion type="single" collapsible>
              <AccordionItem value="advanced">
                <AccordionTrigger>Connected Wallets</AccordionTrigger>
                <AccordionContent className="flex flex-col gap-2 grow">
                  {account?.associatedAddresses?.map(
                    ({ address, type }: any) => (
                      <InfoCardCell
                        title={
                          addressTypeNameMap[type] ??
                          type?.toUpperCase() ??
                          "UNKNOWN"
                        }
                        value={
                          <Button
                            variant="link"
                            className="h-auto w-auto text-md p-0 font-normal"
                            asChild
                          >
                            <NetworkLink to={`/accounts/${address}`}>
                              <CopyableText
                                text={address}
                                displayText={address}
                              />
                            </NetworkLink>
                          </Button>
                        }
                      />
                    )
                  )}
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </CardContent>
        </Card>
      )}
      <Tabs defaultValue="transactions">
        <TabsList className="flex flex-row grow">
          <TabsTrigger value="transactions" className="grow">
            Transactions
          </TabsTrigger>
          <TabsTrigger value="transfers" className="grow">
            Incoming Transfers
          </TabsTrigger>
          {isContract && (
            <TabsTrigger value="events" className="grow">
              Events
            </TabsTrigger>
          )}
          <TabsTrigger value="holdings" className="grow">
            {contract?.isOP20 ? "Top Holders" : "Holdings"}
          </TabsTrigger>
        </TabsList>
        <TabsContent value="transactions">
          <Card className="overflow-hidden">
            <TransactionList
              paginate={true}
              compact={false}
              principal={id}
              key="transactions"
            />
          </Card>
        </TabsContent>
        <TabsContent value="transfers">
          <Card className="overflow-hidden">
            <AccountIncomingTransferList
              paginate={true}
              compact={false}
              principal={id ?? ""}
              key="transfers"
            />
          </Card>
        </TabsContent>
        <TabsContent value="events">
          <Card className="overflow-hidden">
            <EventList
              paginate={true}
              compact={false}
              contractId={id ?? ""}
              key="events"
            />
          </Card>
        </TabsContent>
        <TabsContent value="holdings">
          <Card className="overflow-hidden">
            {id &&
              (contract?.isOP20 ? (
                <TokenHoldersList paginate={true} contract={contract} />
              ) : (
                <TokenBalancesList paginate={true} address={id} />
              ))}
          </Card>
        </TabsContent>
      </Tabs>
      {isContract && (
        <SourceCodeCard
          wast={contract.wast}
          bytecode={contract.bytecode}
          abi={contract.abi && JSON.stringify(contract.abi)}
        />
      )}
    </div>
  );
}
