import { ReactNode } from "react";

export type PageContentHeaderProps = {
  title: string;
  subtitle?: string;
  pre?: ReactNode;
  accessory?: ReactNode;
};

export function PageContentHeader({
  title,
  subtitle,
  pre,
  accessory,
}: PageContentHeaderProps) {
  return (
    <div className="flex flex-row items-center justify-between py-2">
      <div className="flex flex-col items-start">
        {pre && <div className="flex flex-row items-center gap-2">{pre}</div>}
        <div className="font-semibold text-2xl pt-2">{title}</div>
        {subtitle && (
          <div className="font-medium text-sm text-muted-foreground">
            {subtitle}
          </div>
        )}
      </div>
      {accessory && (
        <div className="font-medium text-sm text-muted-foreground">
          {accessory}
        </div>
      )}
    </div>
  );
}
