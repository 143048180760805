import { useMemo, useState } from "react";
import {
  Coins,
  Lock,
  ArrowRightLeft,
  Activity,
  LucideIcon,
  ArrowBigRight,
  Flame,
  Check,
  Group,
  Unlock,
  BadgeDollarSign,
} from "lucide-react";
import { Transition } from "@headlessui/react";

import { CopyableText } from "@/components/CopyableText";
import { cn, base64ToHex } from "@/lib";
import { Button } from "@/components/ui/button";
import { AddressMapView } from "@/components/AddressMapView";
import { ArrayView } from "@/components/ArrayView";
import { Address } from "@/components/Address";

export type EventRowProps = {
  event: {
    type: string;
    properties: Record<string, any>;
    data: string;
  };
  compact?: boolean;
  className?: string;
};

function EventProperty({ k, value }: any) {
  return (
    <div
      className={
        typeof value?.value === "string"
          ? "flex gap-2 items-center"
          : "flex flex-col gap-2 flex-1 py-4"
      }
    >
      <div className="py-1 px-2 rounded-md border font-medium text-muted-foreground text-xs flex-shrink">
        {k}
      </div>
      <div className="text-sm font-normal flex items-center flex-grow flex-1">
        {value?.value instanceof Array ? (
          <ArrayView array={value?.value} />
        ) : value?.value instanceof Object ? (
          <AddressMapView obj={value?.value} />
        ) : value?.type === "ADDRESS" ? (
          <Address
            address={value?.value}
            copyable
            className="text-sm font-normal text-black"
          />
        ) : (
          <CopyableText text={value?.value} displayText={value?.value} />
        )}
      </div>
    </div>
  );
}

export function EventRow({ event, compact = false, className }: EventRowProps) {
  const [showData, setShowData] = useState<boolean>(false);
  const Icon: LucideIcon = useMemo(() => {
    switch (event.type) {
      case "Mint":
        return Coins;
      case "Transfer":
        return ArrowBigRight;
      case "Burn":
        return Flame;
      case "Approve":
        return Check;
      case "PoolCreated":
        return Group;
      case "PoolBurn":
        return Flame;
      case "PoolMint":
        return Coins;
      case "Swap":
        return ArrowRightLeft;
      case "Sync":
        return ArrowRightLeft;
      case "Stake":
        return Lock;
      case "Unstake":
        return Unlock;
      case "Claim":
        return Activity;
      case "WithdrawalRequest":
        return BadgeDollarSign;
      default:
        return Activity;
    }
  }, [event.type]);

  const hex = useMemo(() => {
    return base64ToHex(event.data);
  }, [event.data]);

  return (
    <Transition
      show={true}
      appear={true}
      className={cn(["bg-white text-sm", className])}
      enter="transition-opacity duration-75"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="p-4 flex flex-col items-start w-full">
        <div className="flex flex-row items-center gap-2 w-full justify-between">
          <div className="flex flex-row gap-2 items-center">
            <div className="p-1 bg-black rounded-md inline-block">
              <Icon color="#FFF" className="h-4 w-4" />
            </div>
            <div className="font-semibold text-sm p-0 h-auto" key="title">
              {event.type}
            </div>
          </div>
          <Button
            size="sm"
            variant="link"
            className="p-0 font-medium text-muted-foreground"
            onClick={() => setShowData(!showData)}
          >
            {showData ? "Hide" : "Show"} Event Data
          </Button>
        </div>
        <div className="py-2 flex flex-row gap-2 text-xs w-full">
          <div className="flex flex-col gap-1 justify-center bg-[#333CCFF] flex-grow">
            {Object.entries(event.properties).map(([key, value]) => (
              <EventProperty k={key} value={value} />
            ))}
          </div>
        </div>
        {showData && (
          <div className="flex flex-col gap-1 text-xs border rounded-lg bg-muted text-muted-foreground p-4 w-full">
            <div className="font-semibold uppercase">EVENT DATA</div>
            <CopyableText text={hex} displayText={hex} />
          </div>
        )}
      </div>
    </Transition>
  );
}
