import { Decimal } from "decimal.js";
import { Coins } from "lucide-react";

import { CenteredRowCell } from "@/components/CenteredRowCell";
import { Badge } from "@/components/ui/badge";

import { CommonRow } from "@/components/CommonRow";
import { Address } from "@/components/Address";
import { truncateInMiddle } from "@/lib";

export type TokenRowProps = {
  token: any;
  compact?: boolean;
  className?: string;
};

export function TokenRow({ token, compact = false, className }: TokenRowProps) {
  const metadata = token?.op20Metadata;
  const totalSupply = new Decimal(
    metadata?.totalSupply === "undefined" ? 0 : metadata?.totalSupply || 0
  )
    .dividedBy((10n ** BigInt(metadata?.decimals ?? 0)).toString())
    .toNumber()
    .toLocaleString("en-US", {
      maximumFractionDigits: metadata?.decimals ?? 8,
    });
  return (
    <CommonRow
      icon={Coins}
      title={`${metadata?.name || truncateInMiddle(token?.address)}${metadata?.symbol ? ` (${metadata?.symbol})` : ""}`}
      subtitle={`Total Supply: ${totalSupply}`}
      className={className}
      href={`/accounts/${token?.address}`}
      date={token.deployedAt}
    >
      <CenteredRowCell>
        <Badge variant="outline" className="font-medium gap-1 monospaced">
          <Address address={token.address} copyable />
        </Badge>
      </CenteredRowCell>
    </CommonRow>
  );
}
