import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { RecoilRoot } from "recoil";
import { RecoilURLSync } from "recoil-sync";
import RecoilNexus from "recoil-nexus";

import "@/styles/globals.css";

import { stacksConfig } from "@/config";
import { StacksApiProvider } from "@/context";

import { TooltipProvider } from "@/components/ui/tooltip";
import { AnimatedLayout } from "@/components/AnimatedLayout";
import App from "@/App";
import { BlocksPage } from "@/pages/Blocks/Blocks";
import { BlockPage } from "@/pages/Blocks/Block";
import { TransactionPage as TransactionPage3 } from "@/pages/Transactions/Transaction3";
import { TransactionPage } from "@/pages/Transactions/Transaction";
import { TransactionsPage } from "@/pages/Transactions/Transactions";
import { AccountPage } from "@/pages/Address/Address";
import { TokensPage } from "@/pages/Tokens/Tokens";

const router = createBrowserRouter([
  {
    path: "/",
    element: <AnimatedLayout />,
    children: [
      {
        path: "/",
        element: <App />,
      },
      {
        path: "transactions",
        element: <TransactionsPage />,
      },
      {
        path: "transactions/:id",
        element: <TransactionPage3 />,
      },

      {
        path: "blocks",
        element: <BlocksPage />,
      },
      {
        path: "blocks/:id",
        element: <BlockPage />,
      },

      {
        path: "accounts/:id",
        element: <AccountPage />,
      },
      {
        path: "tokens",
        element: <TokensPage />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <RecoilRoot>
    <RecoilNexus />
    <RecoilURLSync
      location={{ part: "queryParams" }}
      serialize={(value) => `${value}`}
      deserialize={(value) => value}
    >
      <StacksApiProvider config={stacksConfig} tokenConfig={{}}>
        <TooltipProvider>
          <AnimatePresence>
            <RouterProvider router={router} />
          </AnimatePresence>
        </TooltipProvider>
      </StacksApiProvider>
    </RecoilURLSync>
  </RecoilRoot>
);
