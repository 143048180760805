import { Coins } from "lucide-react";
import { Decimal } from "decimal.js";

import { Address } from "@/components/Address";
import { CenteredRowCell } from "@/components/CenteredRowCell";
import { Badge } from "@/components/ui/badge";
import { formatNumber } from "@/lib";

import { CommonRow } from "@/components/CommonRow";

export type HoldingRowProps = {
  balance: any;
  compact?: boolean;
  className?: string;
};

export function FTHoldingRow({ balance, className }: HoldingRowProps) {
  const op20Metadata = balance?.contract?.op20Metadata ?? {};
  const ticker = op20Metadata.symbol ?? "Unknown";
  const decimals = BigInt(op20Metadata.decimals || 0);
  const decimalDiv = 10n ** decimals;
  const amount = new Decimal(balance?.balance || 0).dividedBy(
    decimalDiv.toString()
  );
  const totalSupply = new Decimal(op20Metadata.totalSupply || 0).dividedBy(
    decimalDiv.toString()
  );
  const totalSupplyNumber = totalSupply.toNumber();
  const percentOfSupply = amount
    .dividedBy(totalSupply)
    .times(100)
    .toNumber()
    .toFixed(2);
  return (
    <CommonRow
      icon={Coins}
      title={`${op20Metadata.name ?? "Unknown Token"} ($${ticker})`}
      subtitle={
        <div>
          Supply: {formatNumber(totalSupplyNumber)}
          {totalSupplyNumber > 0 && ` (${formatNumber(percentOfSupply)}%)`}
        </div>
      }
      className={className}
      href={`/accounts/${balance.contractAddress}`}
    >
      <CenteredRowCell>
        <div className="text-md font-normal">{`${formatNumber(
          amount.toNumber()
        )} ${ticker}`}</div>
      </CenteredRowCell>
      <CenteredRowCell>
        <Badge variant="outline" className="font-normal gap-1 monospaced">
          <Address address={balance.contractAddress} copyable />
        </Badge>
      </CenteredRowCell>
    </CommonRow>
  );
}
