import { useContext } from "react";
import { useRecoilValue } from "recoil";
import { StacksApiContext } from "../context";
import { NetworkState } from "../state";

import { useApi } from "./useApi";

export interface GetBlockProps {
  heightOrHash: string;
}

export function useBlock(props: GetBlockProps) {
  const { opnetApi } = useContext(StacksApiContext)!;
  const network = useRecoilValue(NetworkState);

  const apiFn = !/^\d+$/.test(props.heightOrHash)
    ? opnetApi.getBlockByHash
    : opnetApi.getBlockByHeight;

  const { data, loading, error } = useApi(apiFn.bind(opnetApi), [
    network,
    props.heightOrHash,
  ]);

  return {
    block: data,
    loading,
    error,
  };
}
