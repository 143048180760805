import { PropsWithChildren } from "react";
import { LucideIcon } from "lucide-react";

import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";

export type MetricCardProps = {
  loading?: boolean;
  title: string;
  icon?: LucideIcon;
  subtitle?: string;
};

export function MetricCard({
  loading = false,
  title,
  icon: Icon,
  children,
  subtitle,
}: PropsWithChildren<MetricCardProps>) {
  return (
    <Card className="h-32 flex flex-col">
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle className="text-sm font-medium text-muted-foreground">
          {title}
        </CardTitle>
        {Icon && <Icon className="text-muted-foreground h-4 w-4" />}
      </CardHeader>
      <CardContent className="flex flex-col grow justify-center">
        <div className="text-2xl font-bold">
          {loading && <Skeleton className="w-full h-14" />}
          {!loading && children}
        </div>
        {subtitle && (
          <p className="pt-2 text-xs text-muted-foreground">{subtitle}</p>
        )}
      </CardContent>
    </Card>
  );
}
