import { useMemo } from "react";
import { Address as Addr } from "@btc-vision/transaction";
import { CopyableText } from "@/components/CopyableText";
import { NetworkLink } from "@/components/NetworkLink";
import { Button } from "@/components/ui/button";
import { truncateInMiddle, cn } from "@/lib";

export interface AddressProps {
  label?: string;
  address: string;
  copyable?: boolean;
  className?: string;
}

export function Address({ address, label, copyable, className }: AddressProps) {
  const displayAddress = useMemo(() => {
    // todo: check the taproot etc addresses for dead...
    if (Addr.dead().toHex() === address) {
      return "Dead Address";
    }
    return truncateInMiddle(address, 16);
  }, [address]);
  const text = `${label ? `${label}: ` : ""}${address ? displayAddress : ""}`;
  return (
    <Button
      variant="link"
      className={cn(["h-auto w-auto p-0", className])}
      asChild
    >
      <NetworkLink to={`/accounts/${address}`}>
        {copyable ? <CopyableText text={address} displayText={text} /> : text}
      </NetworkLink>
    </Button>
  );
}
